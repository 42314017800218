var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{directives:[{name:"ripple",rawName:"v-ripple",value:(!!_vm.item.children),expression:"!!item.children"}],staticClass:"mb-1 align-center treeRow",style:(`${_vm.depth == 0 ? 'background-color: rgba(200,200,200,0.2)' : ''}`),attrs:{"dense":""},on:{"click":() => (!!_vm.item.children ? _vm.click(_vm.item.key) : () => {})}},[_c('v-col',[_c('div',{class:`mx-${3 * _vm.depth}`,staticStyle:{"user-select":"none"}},[_c('v-icon',{staticClass:"mr-1",class:{ 'spin-me': _vm.expanded.includes(_vm.item.key) },attrs:{"color":`${_vm.item.children ? 'kajot-text' : 'transparent'}`}},[_vm._v(" mdi-menu-right ")]),_vm._v(" "+_vm._s(_vm.item.name)+" ")],1)]),_c('v-col',{staticClass:"justify-right"},[_c('v-row',{attrs:{"dense":""}},[(_vm.loading)?_c('v-layout',_vm._l((4),function(n){return _c('v-skeleton-loader',{key:n,staticClass:"d-flex flex-row mr-8",attrs:{"type":"button","width":"28px","max-height":"28px","loading":_vm.loading}})}),1):_c('v-layout',_vm._l((_vm.checkboxes),function(checkbox){return _c('v-checkbox',_vm._b({key:_vm.item.key + '.' + checkbox.key,staticClass:"checkMenu",attrs:{"readonly":_vm.readonly,"ripple":false,"color":checkbox.color,"on-icon":'mdi-' + checkbox.icon + ' smallCon',"off-icon":'mdi-' +
							checkbox.icon +
							'-outline kajot-disabled--text smallCon',"indeterminate-icon":'mdi-' +
							checkbox.icon +
							'-outline ' +
							checkbox.color +
							'--text smallCon'},on:{"click":function($event){return $event.stopPropagation()},"change":(event) => _vm.handleChange(event, _vm.item, checkbox.key)}},'v-checkbox',_vm.isItemIndeterminate(_vm.item, checkbox.key),false))}),1)],1)],1)],1),_c('v-expand-transition',[(_vm.item.children)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded.includes(_vm.item.key)),expression:"expanded.includes(item.key)"}]},_vm._l((_vm.item.children),function(childItem){return _c('PermissionTree',{key:childItem.key,attrs:{"item":childItem,"depth":_vm.depth + 1,"loading":_vm.loading,"selected":_vm.selected,"expanded":_vm.expanded,"checkboxes":_vm.checkboxes,"readonly":_vm.readonly},on:{"click":(c) => _vm.click(c),"change":_vm.handleChange}})}),1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }